import '../../style/style.css';
import Mapa from '../Nosotros/Mapa';
import Contacto from './Contacto';

function PaginaContacto() {
  return (
    <>
    <Contacto style="margin-top: 0px" />
    <Mapa />
    </>
  );
}

export default PaginaContacto;

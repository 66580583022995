import logo from '../../assets/logo.png';
import '../../style/style.css';
import NavbarNH from './NavbarNH';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocationDot, } from '@fortawesome/free-solid-svg-icons';
import { ContactoCard } from '../ContactoCard/ContactoCard';

function Header() {
  return (
    <>
    <header className='Header container d-none d-lg-block mb-3'>
      <div className="row-logo-contacto">
        <Link to="/">
          <img src={logo} className="logo" alt="NextHaedo. Seguridad Electrónica." />
        </Link>
        <ContactoCard/>
      </div>
    </header>
    <NavbarNH />
    </>
  );
}

export default Header;

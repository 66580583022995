// Import the functions you need from the SDKs you need
// import firebase from "firebase/compat/app"
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
/* import { initializeApp } from "firebase/app"; */
import { getAnalytics } from "firebase/analytics";
import {getDatabase} from "firebase/database"
import { getFirestore } from "firebase/firestore";

import * as autenticacion from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey: "AIzaSyBgNfw_YitzJo7tHAgtm0NzPpihN4jCktU",
    authDomain: "next-haedo.firebaseapp.com",
    projectId: "next-haedo",
    storageBucket: "next-haedo.appspot.com",
    messagingSenderId: "995960211365",
    appId: "1:995960211365:web:ecff3f563260e4bcbf0a4c",
    measurementId: "G-GXPVDJ32JJ"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// console.log(autenticacion)
export const auth = app.auth()
export const db = getDatabase(app) 
export const dbFS = getFirestore(app);

export default app
{/*
firebase.initializeApp(firebaseConfig);
firebase.auth = firebase.auth()
firebase.db = firebase.firestore()
export default firebase
*/}
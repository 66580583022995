import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone,faEnvelope,faLocationDot } from '@fortawesome/free-solid-svg-icons'
export const ContactoCard = (props) => {
  return (
    <div className={'contacto-header ' + props.className}>
      <div className="contacto-linea">
        <FontAwesomeIcon icon={faPhone} size="lg" className='align-self-center icon' />
        <div className='contacto-numeros'>
          <div className="contacto-texto" id="telefono">(011) 6084-3389</div>
          <div className="contacto-texto" id="telefono">(011) 5924-0239</div>
        </div>
      </div>
      <div className="contacto-linea">
        <FontAwesomeIcon icon={faEnvelope} size="lg" className='align-self-center icon' />
        <div className="contacto-texto" id='mail'>ventas@nexthaedo.com.ar</div>
      </div>
      <div className="contacto-linea">
        <FontAwesomeIcon icon={faLocationDot} size="lg" className='align-self-center icon' />
        <div className="contacto-texto" id='ubicacion'>Avenida Rivadavia 14612</div>
      </div>
    </div>
  )
}
